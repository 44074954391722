'use strict';
app.factory("broadcastService", ['$rootScope', 'cacheService', '$ocLazyLoad', '$modal', function ($rootScope, cacheService, $ocLazyLoad,$modal)
{
    //invoke cacheService
    let _initializeCustomKendoFunction = (function _initializeCustomKendoFunction()
    {
        try
        {
            cacheService.Utility.initializeCustomKendoFunction();
        }
        catch { }
        return _initializeCustomKendoFunction;
    }());

    let broadcastServiceFactory = {};

    let _broadcast = function (event, data)
    {
        $rootScope.$broadcast(event, data);
    };

    let _generateAttachmentID = function ()
    {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    };

    let langKey = $rootScope.userSetting ? $rootScope.userSetting.langKey : 1;

    let _getLabelDesc = function (labelKey, defaultValue = "")
    {
        langKey = $rootScope.userSetting ? $rootScope.userSetting.langKey : 1;

        return _.get($rootScope.label, `${labelKey}.${langKey}.LabelDesc`, defaultValue);
    }

    let _openAccountForm = function (source)
    {
        //All Account
        //output.key = gridSelected.TransHdrKey;
        let output = {};

        if (source == 'PI')
        {
            output.requestedForm = "enterPI";
            output.MenuKey = 49;
        }
        else if (source == 'CN')
        {
            output.requestedForm = "enterCN";
            output.MenuKey = 41;
        }
        else if (source == 'SB')
        {
            output.requestedForm = "enterSB";
            output.MenuKey = 52;
        }
        else if (source == 'CRP')
        {
            output.requestedForm = "enterCP";
            output.MenuKey = 45;
        }
        else if (source == 'SI' || source == 'SIE' || source == 'SIC')
        {
            output.requestedForm = "enterSI";
            output.MenuKey = 50;
        }
        else if (source == 'DN')
        {
            output.requestedForm = "enterDN";
            output.MenuKey = 43;
        }
        else if (source == 'DRP')
        {
            output.requestedForm = "enterDPRecv";
            output.MenuKey = 191;
        }
        else if (source == 'BP')
        {
            output.requestedForm = "enterBankPayment";
            output.MenuKey = 39;
        }
        else if (source == 'BR')
        {
            output.requestedForm = "enterBankReceive";
            output.MenuKey = 40;
        }
        else if (source == 'CP')
        {
            output.requestedForm = "enterCashPayment";
            output.MenuKey = 47;
        }
        else if (source == 'CR')
        {
            output.requestedForm = "enterCashReceive";
            output.MenuKey = 48;
        }
        else if (source == 'DM')
        {
            output.requestedForm = "enterDM";
            output.MenuKey = 794;
        }
        else if (source == 'BD')
        {
            output.requestedForm = "enterBadDebtAR";
            output.MenuKey = 257;
        }
        else if (source == 'TA')
        {
            output.requestedForm = "enterBadDebtAP";
            output.MenuKey = 256;
        }
        else if (source == 'TD')
        {
            output.requestedForm = "enterTaxGST";
            output.MenuKey = $rootScope.CtryVersion =="M" ? 194 : 1402;
        }
        else if (source == 'SBR')
        {
            output.requestedForm = "enterSBReceipt";
            output.MenuKey = 368;
        }
        else if (source == 'AJ')
        {
            output.requestedForm = "enterYearEndAdj";
            output.MenuKey = 795;
        }
        else if (source == 'CT')
        {
            output.requestedForm = "enterTransClear";
            output.MenuKey = 796;
        }
        else
        {
            output.requestedForm = "enterGL";
            output.MenuKey = 46;
        }

        return output;
    }

    let _openInventoryForm = function (source)
    {
        let output = {};

        if (source == 'ADJ')
        {
            output.requestedForm = "adjenter";
            output.MenuKey = 734;
        }
        else if (source == 'MIG')
        {
            output.requestedForm = "invmigenter";
            output.MenuKey = 826;
        }
        // not using might wrong
        //else if (source == 'ISU') {
        //    output.requestedForm = "isuenter";
        //    output.MenuKey = 729;
        //}
        //else if (source == 'IRN') {
        //    output.requestedForm = "isurtnenter";
        //    output.MenuKey = 728;
        //}
        //else if (source == 'RTN') {
        //    output.requestedForm = "stockpurrtn";
        //    output.MenuKey = 727;
        //}
        //else if (source == 'RCV') {
        //    output.requestedForm = "storcventer";
        //    output.MenuKey = 726;
        //}
        //else if (source == 'TRN') {
        //    output.requestedForm = "trnenter";
        //    output.MenuKey = 732;
        //}
        //else if (source == 'OURCV') {
        //    output.requestedForm = "ousrenter";
        //    output.MenuKey = 730;
        //}
        //else if (source == 'OUTRN') {
        //    output.requestedForm = "outrnenter";
        //    output.MenuKey = 731;
        //}

        return output;
    }

    let _getMsg = function (msgKey, defaultValue)
    {
        langKey = $rootScope.userSetting ? $rootScope.userSetting.langKey : 1;

        if (!defaultValue) defaultValue = { MsgKey: null, MsgType: null, MsgIcon: null, MsgHdr: "Msg Not found", MsgBody: "Msg Not found" };
        return _.get($rootScope.msg, `${msgKey}.${langKey}`, defaultValue);
    }

    let _convertNumToEng = function (value)
    {
        let ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
        let tens = ['', '', 'twenty-', 'thirty-', 'forty-', 'fifty-', 'sixty-', 'seventy-', 'eighty-', 'ninety-'];
        let teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
        let tensss =
            [
                '', 'One', 'Two',
                'Three',
                'Four',
                'Five',
                'Six',
                'Seven',
                'Eight',
                'Nine',
                'Ten',
                'Eleven',
                'Twelve',
                'Thirteen',
                'Fourteen',
                'Fifteen',
                'Sixteen',
                'Seventeen',
                'Eighteen',
                'Nineteen',
                'Twenty',
                'Twenty-one',
                'Twenty-two',
                'Twenty-three',
                'Twenty-four',
                'Twenty-five',
                'Twenty-six',
                'Twenty-seven',
                'Twenty-eight',
                'Twenty-nine',
                'Thirty',
                'Thirty-one',
                'Thirty-two',
                'Thirty-three',
                'Thirty-four',
                'Thirty-five',
                'Thirty-six',
                'Thirty-seven',
                'Thirty-eight',
                'Thirty-nine',
                'Forty',
                'Forty-one',
                'Forty-two',
                'Forty-three',
                'Forty-four',
                'Forty-five',
                'Forty-six',
                'Forty-seven',
                'Forty-eight',
                'Forty-nine',
                'Fifty',
                'Fifty-one',
                'Fifty-two',
                'Fifty-three',
                'Fifty-four',
                'Fifty-five',
                'Fifty-six',
                'Fifty-seven',
                'Fifty-eight',
                'Fifty-nine',
                'Sixty',
                'Sixty-one',
                'Sixty-two',
                'Sixty-three',
                'Sixty-four',
                'Sixty-five',
                'Sixty-six',
                'Sixty-seven',
                'Sixty-eight',
                'Sixty-nine',
                'Seventy',
                'Seventy-one',
                'Seventy-two',
                'Seventy-three',
                'Seventy-four',
                'Seventy-five',
                'Seventy-six',
                'Seventy-seven',
                'Seventy-eight',
                'Seventy-nine',
                'Eighty',
                'Eighty-one',
                'Eighty-two',
                'Eighty-three',
                'Eighty-four',
                'Eighty-five',
                'Eighty-six',
                'Eighty-seven',
                'Eighty-eight',
                'Eighty-nine',
                'Ninety',
                'Ninety-one',
                'Ninety-two',
                'Ninety-three',
                'Ninety-four',
                'Ninety-five',
                'Ninety-six',
                'Ninety-seven',
                'Ninety-eight',
                'Ninety-nine'
            ]

        function convert_millions(num)
        {
            if (num >= 1000000)
            {
                return convert_millions(Math.floor(num / 1000000)) + " million " + convert_thousands(num % 1000000);
            }
            else
            {
                return convert_thousands(num);
            }
        }

        function convert_thousands(num)
        {
            if (num >= 1000)
            {
                return convert_hundreds(Math.floor(num / 1000)) + " thousand " + convert_hundreds(num % 1000);
            }
            else
            {
                return convert_hundreds(num);
            }
        }

        function convert_hundreds(num)
        {
            if (num > 99)
            {
                return ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100);
            }
            else
            {
                return convert_tens(num);
            }
        }

        function convert_tens(num)
        {
            if (num < 10)
                return ones[num];
            else if (num >= 10 && num < 20)
                return teens[num - 10];
            else
            {
                return tens[Math.floor(num / 10)] + "" + ones[num % 10];
            }
        }

        function convert(num)
        {
            if (num == 0)
                return "zero";
            else
                return convert_millions(num);
        }

        let a = value % 1;
        let b = "";
        if (a != 0)
        {
            b = tensss[_customDecimalRounding(a.toFixed(2) * 100)];
        }

        var english = "";
        var english1 = "";
        if (value > 1000000)
            english = convert_millions(value - (value % 1));
        else if (value >= 1000)
            english = convert_thousands(value - (value % 1));
        else if (value > 99)
            english = convert_hundreds(value - (value % 1));
        else if (value < 100)
            english = convert_tens(value - (value % 1));

        if ((value % 1) != 0)
        {
            if (english1 != "")
            {
                english1 = english + " And " + b + " Cents Only";
            }
            else
            {
                english1 = b + " Cents Only";
            }
        }
        else
        {
            if (english1 != "")
            {
                english1 = english + b + " Only";
            }
            else
            {
                english1 = b + " Only";
            }
        }

        return english1;
    }

    let _convertMonthToString = function (value)
    {
        let compare = value.toString();

        switch (compare)
        {
            case "1": return _getLabelDesc(2071);
            case "2": return _getLabelDesc(2072);
            case "3": return _getLabelDesc(2073);
            case "4": return _getLabelDesc(2074);
            case "5": return _getLabelDesc(2075);
            case "6": return _getLabelDesc(2076);
            case "7": return _getLabelDesc(2077);
            case "8": return _getLabelDesc(2078);
            case "9": return _getLabelDesc(2079);
            case "10": return _getLabelDesc(2080);
            case "11": return _getLabelDesc(2081);
            case "12": return _getLabelDesc(2082);
            default: return value;
        }
    }

    let _convertMinutesToDaysHoursMinutes = function (value)
    {
        if (isNaN(value) || parseInt(value) == 0)
            return "0";

        let totalMinute = parseInt(value);
        let result = "";

        if (totalMinute < 0)
        {
            totalMinute = (totalMinute).Nabs();
            result = "- ";
        }
        if (totalMinute >= 1440)  //1 day has 1440 minutes
            result += (totalMinute).Ndiv(1440).Nfloor() + "d ";
        if (totalMinute >= 60) // 1 hour has 60 minutes
            result += (totalMinute).Nmod(1440).Ndiv(60).Nfloor() + "h ";
        if (totalMinute > 0)
            result += (totalMinute).Nmod(1440).Nmod(60) + "m";

        return result;
    }

    let _getTimeZoneDataSource = function ()
    {
        return [{ TimeZone: "Dateline Standard Time", Time: "(GMT-12:00) International Date Line West" },
        { TimeZone: "Samoa Standard Time", Time: "(GMT-11:00) Midway Island, Samoa" },
        { TimeZone: "Hawaiian Standard Time", Time: "(GMT-10:00) Hawaii" },
        { TimeZone: "Alaskan Standard Time", Time: "(GMT-09:00) Alaska" },
        { TimeZone: "Pacific Standard Time (Mexico)", Time: "(GMT-08:00) Tijuana, Baja California" },
        { TimeZone: "Pacific Standard Time", Time: "(GMT-08:00) Pacific Time (US and Canada)" },
        { TimeZone: "Mountain Standard Time", Time: "(GMT-07:00) Mountain Time (US and Canada)" },
        { TimeZone: "Mountain Standard Time (Mexico)", Time: "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
        { TimeZone: "US Mountain Standard Time", Time: "(GMT-07:00) Arizona" },
        { TimeZone: "Canada Central Standard Time", Time: "(GMT-06:00) Saskatchewan" },
        { TimeZone: "Central Standard Time (Mexico)", Time: "(GMT-06:00) Guadalajara, Mexico City, Monterrey" },
        { TimeZone: "Central Standard Time", Time: "(GMT-06:00) Central Time (US and Canada)" },
        { TimeZone: "Central America Standard Time", Time: "(GMT-06:00) Central America" },
        { TimeZone: "US Eastern Standard Time", Time: "(GMT-05:00) Indiana (East)" },
        { TimeZone: "Eastern Standard Time", Time: "(GMT-05:00) Eastern Time (US and Canada)" },
        { TimeZone: "SA Pacific Standard Time", Time: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco" },
        { TimeZone: "Venezuela Standard Time", Time: "(GMT-04:30) Caracas" },
        { TimeZone: "Pacific SA Standard Time", Time: "(GMT-04:00) Santiago" },
        { TimeZone: "SA Western Standard Time", Time: "(GMT-04:00) La Paz" },
        { TimeZone: "Atlantic Standard Time", Time: "(GMT-04:00) Atlantic Time (Canada)" },
        { TimeZone: "Newfoundland Standard Time", Time: "(GMT-03:30) Newfoundland" },
        { TimeZone: "Greenland Standard Time", Time: "(GMT-03:00) Greenland" },
        { TimeZone: "SA Eastern Standard Time", Time: "(GMT-03:00) Georgetown" },
        { TimeZone: "Argentina Standard Time", Time: "(GMT-03:00) Buenos Aires" },
        { TimeZone: "E. South America Standard Time", Time: "(GMT-03:00) Brasilia" },
        { TimeZone: "Mid-Atlantic Standard Time", Time: "(GMT-02:00) Mid-Atlantic" },
        { TimeZone: "Cape Verde Standard Time", Time: "(GMT-01:00) Cape Verde Islands" },
        { TimeZone: "Azores Standard Time", Time: "(GMT-01:00) Azores" },
        { TimeZone: "Tonga Standard Time", Time: "(GMT+13:00) Nuku'alofa" },
        { TimeZone: "Fiji Standard Time", Time: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands" },
        { TimeZone: "New Zealand Standard Time", Time: "(GMT+12:00) Auckland, Wellington" },
        { TimeZone: "Central Pacific Standard Time", Time: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia" },
        { TimeZone: "Vladivostok Standard Time", Time: "(GMT+10:00) Vladivostok" },
        { TimeZone: "Tasmania Standard Time", Time: "(GMT+10:00) Hobart" },
        { TimeZone: "West Pacific Standard Time", Time: "(GMT+10:00) Guam, Port Moresby" },
        { TimeZone: "AUS Eastern Standard Time", Time: "(GMT+10:00) Canberra, Melbourne, Sydney" },
        { TimeZone: "E. Australia Standard Time", Time: "(GMT+10:00) Brisbane" },
        { TimeZone: "AUS Central Standard Time", Time: "(GMT+09:30) Darwin" },
        { TimeZone: "Cen. Australia Standard Time", Time: "(GMT+09:30) Adelaide" },
        { TimeZone: "Korea Standard Time", Time: "(GMT+09:00) Seoul" },
        { TimeZone: "Tokyo Standard Time", Time: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
        { TimeZone: "Taipei Standard Time", Time: "(GMT+08:00) Taipei" },
        { TimeZone: "W. Australia Standard Time", Time: "(GMT+08:00) Perth" },
        { TimeZone: "Singapore Standard Time", Time: "(GMT+08:00) Kuala Lumpur, Singapore" },
        { TimeZone: "North Asia East Standard Time", Time: "(GMT+08:00) Irkutsk, Ulaanbaatar" },
        { TimeZone: "China Standard Time", Time: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi" },
        { TimeZone: "North Asia Standard Time", Time: "(GMT+07:00) Krasnoyarsk" },
        { TimeZone: "SE Asia Standard Time", Time: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
        { TimeZone: "Myanmar Standard Time", Time: "(GMT+06:30) Yangon Rangoon" },
        { TimeZone: "Central Asia Standard Time", Time: "(GMT+06:00) Astana, Dhaka" },
        { TimeZone: "N. Central Asia Standard Time", Time: "(GMT+06:00) Almaty, Novosibirsk" },
        { TimeZone: "Nepal Standard Time", Time: "(GMT+05:45) Kathmandu" },
        { TimeZone: "Sri Lanka Standard Time", Time: "(GMT+05:30) Sri Jayawardenepura" },
        { TimeZone: "India Standard Time", Time: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
        { TimeZone: "West Asia Standard Time", Time: "(GMT+05:00) Tashkent" },
        { TimeZone: "Pakistan Standard Time", Time: "(GMT+05:00) Islamabad, Karachi" },
        { TimeZone: "Ekaterinburg Standard Time", Time: "(GMT+05:00) Ekaterinburg" },
        { TimeZone: "Afghanistan Standard Time", Time: "(GMT+04:30) Kabul" },
        { TimeZone: "Caucasus Standard Time", Time: "(GMT+04:00) Yerevan" },
        { TimeZone: "Azerbaijan Standard Time", Time: "(GMT+04:00) Baku" },
        { TimeZone: "Arabian Standard Time", Time: "(GMT+04:00) Abu Dhabi, Muscat" },
        { TimeZone: "Iran Standard Time", Time: "(GMT+03:30) Tehran" },
        { TimeZone: "Georgian Standard Time", Time: "(GMT+03:00) Tbilisi" },
        { TimeZone: "E. Africa Standard Time", Time: "(GMT+03:00) Nairobi" },
        { TimeZone: "Russian Standard Time", Time: "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
        { TimeZone: "Arab Standard Time", Time: "(GMT+03:00) Kuwait, Riyadh" },
        { TimeZone: "Arabic Standard Time", Time: "(GMT+03:00) Baghdad" },
        { TimeZone: "E. Europe Standard Time", Time: "(GMT+02:00) Minsk" },
        { TimeZone: "Israel Standard Time", Time: "(GMT+02:00) Jerusalem" },
        { TimeZone: "FLE Standard Time", Time: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius" },
        { TimeZone: "South Africa Standard Time", Time: "(GMT+02:00) Harare, Pretoria" },
        { TimeZone: "Egypt Standard Time", Time: "(GMT+02:00) Cairo" },
        { TimeZone: "GTB Standard Time", Time: "(GMT+02:00) Athens, Bucharest, Istanbul" },
        { TimeZone: "W. Central Africa Standard Time", Time: "(GMT+01:00) West Central Africa" },
        { TimeZone: "Central European Standard Time", Time: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
        { TimeZone: "Romance Standard Time", Time: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
        { TimeZone: "Central Europe Standard Time", Time: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
        { TimeZone: "W. Europe Standard Time", Time: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
        { TimeZone: "Greenwich Standard Time", Time: "(GMT) Monrovia, Reykjavik" },
        { TimeZone: "GMT Standard Time", Time: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London" },
        { TimeZone: "Morocco Standard Time", Time: "(GMT) Casablanca" },
        ];
    }

    let _contructFilter = function (columnsfilter, grid, columns, filter)
    {
        if (typeof columnsfilter === "string"
            && typeof grid != "undefined"
            && Array.isArray(columns)
            && typeof filter != "undefined")
        {
            let _searchValue = columnsfilter.trim();

            let _isNumber = !isNaN(+(_searchValue.replaceAll(",", '').replaceAll(".", '').trim())) && isFinite(+(_searchValue.replaceAll(",", '').replaceAll(".", '').trim())) && (_searchValue.replaceAll(",", '').replaceAll(".", '').trim()) != "";
            let _isDate_DD_MM_YYYY = moment(_searchValue, "DD/MM/YYYY", true).isValid();
            let _isDate_D_MM_YYYY = moment(_searchValue, "D/MM/YYYY", true).isValid();
            let _isDate_DD_M_YYYY = moment(_searchValue, "DD/M/YYYY", true).isValid();
            let _isDate_D_M_YYYY = moment(_searchValue, "D/M/YYYY", true).isValid();
            let _isString = !_isDate_DD_MM_YYYY && !_isDate_D_MM_YYYY && !_isDate_DD_M_YYYY && !_isDate_D_M_YYYY;

            //attempt to parse date
            let _parsedDate =
                _isDate_DD_MM_YYYY ? moment(_searchValue, "DD/MM/YYYY", true).toDate()
                    : _isDate_D_MM_YYYY ? moment(_searchValue, "D/MM/YYYY", true).toDate()
                        : _isDate_DD_M_YYYY ? moment(_searchValue, "DD/M/YYYY", true).toDate()
                            : _isDate_D_M_YYYY ? moment(_searchValue, "D/M/YYYY", true).toDate() : null;

            //attempt to parse number by determine culture
            let _parsedNumber = _searchValue;
            if (_isNumber)
            {
                //find decimal separator based on culturekey
                let _lastIndexOfDecimalPt = _searchValue.lastIndexOf($rootScope.CultureKey == 2 ? "," : ".");

                //decimal separator found, assume it is decimal
                if (_lastIndexOfDecimalPt >= 0)
                {
                    let numeric_part = _searchValue.substr(0, _lastIndexOfDecimalPt).replaceAll(",", '').replaceAll(".", '').trim();
                    if (numeric_part == "")
                    {
                        numeric_part = "0";
                    }

                    let decimal_part = _searchValue.substr(_lastIndexOfDecimalPt + 1, _searchValue.length).replaceAll(",", '').replaceAll(".", '').trim();
                    if (decimal_part == "")
                    {
                        decimal_part = "0";
                    }

                    _parsedNumber = numeric_part + "." + decimal_part;
                }
                //assume integer and remove everything
                else
                {
                    _parsedNumber = _searchValue.replaceAll(",", '').replaceAll(".", '').trim();
                }
            }

            columns.forEach(function (x)
            {
                if (typeof x != "undefined" && x != null
                    && typeof x.field != "undefined" && x.field != null)
                {
                    let type = (grid?.dataSource?.options?.schema?.model?.fields[x.field]?.type) ?? "";

                    //search number column only when search value is a valid number
                    if ((type == 'number' || type == 'decimal') && _isNumber)
                    {
                        filter.filters.push({
                            field: x.field,
                            operator: 'eq',
                            value: +_parsedNumber
                        });
                    }
                    //search date column only when search value is a valid date
                    else if (type == 'date' && (_isDate_DD_MM_YYYY || _isDate_D_MM_YYYY || _isDate_DD_M_YYYY || _isDate_D_M_YYYY))
                    {
                        filter.filters.push({
                            field: x.field,
                            operator: 'eq',
                            value: _parsedDate
                        });
                    }
                    else if (type == 'string' && _isString)
                    {
                        filter.filters.push({
                            field: x.field,
                            operator: 'contains',
                            value: _searchValue
                        });
                    }
                }
            });

            return filter;
        }
    }

    let _customDecimalRounding = function (value, decimalpt, isConvertToString)
    {
        return (typeof isConvertToString === "undefined") ? ((typeof value === "number" || typeof value === "string") ? value : 0).NtoDP(typeof decimalpt === "number" ? decimalpt : 0) : ((typeof value === "number" || typeof value === "string") ? value : 0).NtoDP(typeof decimalpt === "number" ? decimalpt : 0).NtoFormattedString(typeof decimalpt === "number" ? decimalpt : 0);
    };

    let _checkRefSubject = function (Source)
    {
        let refSubject = 0;
        switch (Source)
        {
            case 'PR':
                refSubject = 1;
                break;
            case 'PO':
                refSubject = 2;
                break;
            case 'SR':
                refSubject = 3;
                break;
            case 'BP':
                refSubject = 13;
                break;
            case 'PI':
                refSubject = 14;
                break;
            case 'SBR':
                refSubject = 15;
                break;
            case 'CN':
                refSubject = 16;
                break;
            case 'DN':
                refSubject = 17;
                break;
            case 'BR':
                refSubject = 18;
                break;
            case 'CR':
                refSubject = 19;
                break;
            case 'CP':
                refSubject = 20;
                break;
            case 'CRP':
                refSubject = 21;
                break;
            case 'DM':
                refSubject = 22;
                break;
            case 'DRP':
                refSubject = 23;
                break;
            case 'GJ':
                refSubject = 24;
                break;
            case 'SB':
                refSubject = 25;
                break;
            case 'SI':
                refSubject = 26;
                break;
            case 'TD':
                refSubject = 27;
                break;
            case 'CT':
                refSubject = 28;
                break;
            case 'AJ':
                refSubject = 29;
                break;
            case 'ADJ':
                refSubject = 30;
                break;
            case 'RCV':
                refSubject = 31;
                break;
            case 'RTN':
                refSubject = 32;
                break;
            case 'ISURTN':
                refSubject = 33;
                break;
            case 'ISU':
                refSubject = 34;
                break;
            case 'OUTRN':
                refSubject = 35;
                break;
            case 'TRN':
                refSubject = 36;
                break;
            case 'STOCHK':
                refSubject = 37;
                break;
            case 'OUISU':
                refSubject = 38;
                break;
            case 'MIG':
                refSubject = 39;
                break;
            case 'UNCULAREA':
                refSubject = 40;
                break;
            case 'BANKRECON':
                refSubject = 41;
                break;
            case 'EMPY':
                refSubject = 49;
                break;
            case 'NTFY':
                refSubject = 50;
                break;
            case 'FWD':
                refSubject = 56;
                break;
            case 'XR':
                refSubject = 57;
                break;
            case 'JR':
                refSubject = 58;
                break;
            case 'QRF':
                refSubject = 59;
                break;
            case 'QRS':
                refSubject = 60;
                break;

            default: 0
        }
        return refSubject;
    }

    let _viewApprover = function (Source, Key) {
        $ocLazyLoad.load({
            name: "pendingApprover",
            files: ["master/controllers/pendingApproverController.js"]
        }).then(function () {
            var modalInstance = $modal.open({
                backdrop: 'static',
                templateUrl: '/master/views/pendingApprover.html',
                controller: 'pendingApproverController',
                resolve:
                {
                    aValue: function () {
                        return {
                            winExpdApproverFormVisible: true,
                            form: Source,
                            key: Key,

                        };
                    }
                }
            });
        });
    }

    app.developerTest = function (parameter, type)
    {
        if (typeof app.____ === "boolean" && app.____ === !0)
        {
            if ($rootScope.getConnectionState("main") === $.signalR.connectionState.disconnected)
            {
                console.log("%cInvalid state, please login!", 'background:red;color:#fff');
            }
            else if ($rootScope.getConnectionState("main") !== $.signalR.connectionState.connected)
            {
                console.log("%cInvalid state, please wait/re-login!", 'background:red;color:#fff');
            }
            else
            {
                cacheService.Utility.devTest(parameter, type);
            }
        }
    };

    app.refreshCachedData = function (type)
    {
        if ($rootScope.getConnectionState("main") === $.signalR.connectionState.disconnected)
        {
            console.log("%cInvalid state, please login!", 'background:red;color:#fff');
        }
        else
        {
            cacheService.Utility.devTest(8, type);
        }
    };

    broadcastServiceFactory.broadcast = _broadcast;
    broadcastServiceFactory.generateAttachmentID = _generateAttachmentID;
    broadcastServiceFactory.initializeCustomKendoFunction = _initializeCustomKendoFunction;
    broadcastServiceFactory.getUIInformation = cacheService.Request.getUIInformation;
    broadcastServiceFactory.parseStringToDecimal = cacheService.Utility.parseStringToDecimal;
    broadcastServiceFactory.customDecimalRounding = _customDecimalRounding;
    broadcastServiceFactory.getLabelDesc = _getLabelDesc;
    broadcastServiceFactory.getMsg = _getMsg;
    broadcastServiceFactory.convertNumToEng = _convertNumToEng;
    broadcastServiceFactory.convertMonthToString = _convertMonthToString;
    broadcastServiceFactory.getTimeZoneDataSource = _getTimeZoneDataSource;
    broadcastServiceFactory.logGeneralJsError = cacheService.Logging.logGeneralJsError;
    broadcastServiceFactory.postPerfDBLog = cacheService.Logging.postPerfDBLog;
    broadcastServiceFactory.convertMinutesToDaysHoursMinutes = _convertMinutesToDaysHoursMinutes;
    $rootScope.openAccountForm = _openAccountForm;
    broadcastServiceFactory.openAccountForm = _openAccountForm;
    $rootScope.openInventoryForm = _openInventoryForm;
    broadcastServiceFactory.openInventoryForm = _openInventoryForm;
    broadcastServiceFactory.guid = cacheService.Utility.generateGUID;
    broadcastServiceFactory.s4 = cacheService.Utility.generateRandomID;
    broadcastServiceFactory.getClientKey = cacheService.Utility.getSessionClientKey;
    broadcastServiceFactory.speedTest = cacheService.Utility.speedTest;
    broadcastServiceFactory.contructFilter = _contructFilter;
    broadcastServiceFactory.checkRefSubject = _checkRefSubject;
    broadcastServiceFactory.viewApprover = _viewApprover;

    return broadcastServiceFactory;
}]);